<template>
  <div class="my">
    <div class="title">
      {{ $t("setting.title1") }}
    </div>
    <div class="myList">
      <div class="myFeedback listItem" style="border: 0;">
        <div class="listItem-left">
          <span>UID</span>
        </div>
        <div class="listItem-right">
          <span>{{ uid }}</span>
          <!-- <img src="../assets/my/zhankai@2x.png" alt /> -->
        </div>
      </div>
    </div>
    <div class="title">
      {{ $t("setting.title2") }}
    </div>
    <div class="myList">
      <!-- <div class="myFeedback listItem" @click="go('/feedBack')">
        <div class="listItem-left">
          <span>Login password</span>
        </div>
        <div class="listItem-right">
          <img src="../assets/my/zhankai@2x.png" alt />
        </div>
      </div> -->
      <div class="myFeedback listItem" style="border: 0;">
        <div class="listItem-left">
          <span> {{ $t("setting.title2_content") }}</span>
        </div>
        <div class="listItem-right">
          <span>{{ email }}</span>
          <!-- <img src="../assets/my/zhankai@2x.png" alt /> -->
        </div>
      </div>
      <!-- <div class="myFeedback listItem" style="border: 0;" @click="go('/feedBack')">
        <div class="listItem-left">
          <span>手机号</span>
        </div>
        <div class="listItem-right">
          <img src="../assets/my/zhankai@2x.png" alt />
        </div>
      </div> -->
    </div>

    <div class="title">
      {{ $t("setting.title3_content") }}
    </div>
    <div class="myList">
      <div class="myFeedback listItem" style="border: 0;">
        <div class="listItem-left">
          <span> {{ $t("setting.title3_content") }}</span>
        </div>
        <div class="listItem-right" style="width: 130px;">
          <van-field
            @click="showPicker = true"
            style="width:100%;font-size: 15px;color: #fff!important;font-weight: bolder;"
            v-model="network"
            title="选择网络"
            readonly
            name="picker"
            is-link
          />
        </div>
      </div>
    </div>
    <!-- <div class="title">
      帮助与反馈
    </div>
    <div class="myList">
      <div class="myFeedback listItem" @click="go('/feedBack')">
        <div class="listItem-left">
          <span>帮助中心</span>
        </div>
        <div class="listItem-right">
          <img src="../assets/my/zhankai@2x.png" alt />
        </div>
      </div>
      <div class="myFeedback listItem" @click="go('/feedBack')">
        <div class="listItem-left">
          <span>联系客服</span>
        </div>
        <div class="listItem-right">
          <img src="../assets/my/zhankai@2x.png" alt />
        </div>
      </div>
      <div class="myFeedback listItem"  style="border: 0;" @click="go('/feedBack')">
        <div class="listItem-left">
          <span>关于我们</span>
        </div>
        <div class="listItem-right">
          <img src="../assets/my/zhankai@2x.png" alt />
        </div>
      </div>
    </div> -->
    <button class="exitButton" @click="exit">
      {{ $t("setting.log_out") }}
    </button>
    <div class="exitBg" v-if="isShowExitView">
      <div class="exitWhiteView">
        <div class="exitWhiteView_Top">
          <p class="exitTitle">
            {{ $t("setting.log_out_title") }}
          </p>
        </div>
        <p class="exitSubTitle">
          {{ $t("setting.log_out_content") }}
        </p>
        <div class="exitSelectBtnBg">
          <div class="sureBtn" @click="sure()">
            {{ $t("setting.log_out_button1") }}
          </div>
          <div class="cancelBtn" @click="cancel()">
            {{ $t("setting.log_out_button2") }}
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker
        :disabled="true"
        :columns="columns"
        :columns-field-names="customFieldName"
        @cancel="showPicker = false"
        @confirm="setIn18"
      />
    </van-popup>
  </div>
</template>

<script>
import { Locale } from "vant"

// import useI18n from "vue-i18n"
// const { locale } = useI18n()
export default {
  name: "my",
  data() {
    return {
      coinNumber: "",
      coinType: "ETH",
      filCoinType: "FIL",
      coinCny: "",
      authStatus: null,
      isShowExitView: false,
      rmbBalance: "",
      filBalance: "",
      filRmb: "",
      uid: "",
      email: "",
      network:
        localStorage.getItem("currentLanage") == "en" ? "English" : "简体中文", // 语种
      showPicker: false,
      columns: [
        {
          name: "简体中文",
          id: "zh",
          key: 1,
          active: true,
        },
        {
          name: "English",
          id: "en",
          key: 2,
          active: false,
        },
      ],
      customFieldName: {
        text: "name",
      },
    }
  },
  created() {
    this.getMeInforMation()
  },
  components: {},
  mounted() {},

  computed() {
    return this.$i18n.locale
  },
  methods: {
    go(url) {
      this.$router.push(url)
    },
    setIn18(item) {
      this.network = item.name
      this.columns.map((items) => {
        if (item.name == items.name) {
          Locale.use(item.id, this.$i18n.messages[item.id])
          // Locale.use(localStorage.getItem("currentLanage") || default_lang, messages[localStorage.getItem("currentLanage") || default_lang])
          this.$i18n.locale = item.id
          localStorage.setItem("currentLanage", item.id)
          // window.location.reload()
        }
      })
      this.showPicker = false
    },
    // 获取我的个人信息
    getMeInforMation() {
      this.$request
        .post("/user/userInfo")
        .then(
          (res) => {
            if (res.code == 200) {
              this.email = res.data.email
              this.uid = res.data.uid
            } else {
              // this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    //点击退出
    exit() {
      this.isShowExitView = true
    },
    //提现
    withdrawa(symbol) {
      this.$router.push({
        path: "/Withdrawal",
        query: {
          symbol: symbol,
        },
      })
    },
    //去交易记录
    gotoRecordList(symbol) {
      if (symbol != "") {
        this.$router.push({
          path: "/MentionRecord",
          query: {
            symbol: symbol,
          },
        })
      } else {
        this.$router.push({
          path: "/electricity",
          // query: {
          //   symbol: symbol
          // }
        })
      }
    },
    //确定退出
    sure() {
      localStorage.removeItem("token")
      this.$router.push("/login")
      this.isShowExitView = false
    },
    //取消退出
    cancel() {
      this.isShowExitView = false
    },
  },
}
</script>
<style scoped lang="scss">
.my {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-items: center;
  background: #f9f9f9;
  padding: 0 20px 20px;
  font-weight: bolder;
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  .exitBg {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.2s ease-in-out;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .exitWhiteView {
      width: 314px;
      height: 190px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
      border-radius: 3px;
      display: flex;
      flex-direction: column;
    }
    .exitWhiteView_Top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .exitTitle {
      margin-left: 15px;
      margin-top: 20px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
    .exitSubTitle {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 15px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;
      text-align: left;
    }
    .exitSelectBtnBg {
      margin-top: 17px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .sureBtn {
      width: 66px;
      height: 36px;
      background: #f0f2f5;
      border-radius: 3px;
      border: 1px solid #d7dbe0;
      color: #333333;
      line-height: 36px;
      font-size: 14px;
      margin-right: 10px;
    }
    .cancelBtn {
      width: 62px;
      height: 36px;
      background: #3d7fff;
      border-radius: 3px;
      font-size: 14px;
      color: #ffffff;
      line-height: 36px;
      margin-right: 15px;
    }
  }
  .title {
    width: 100%;
    height: 20px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 20px 0 12px;
  }
  .myList {
    width: 345px;
    // min-height: 140px;
    background: #ffffff;
    padding: 0 15px;
    border-radius: 8px;
    .listItem {
      height: 70px; // padding: 26px 0;
      border-bottom: 1px solid #ededed;
      display: flex;
      justify-content: space-between;
      .listItem-left {
        // height: 20px;
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          color: #333333;
        }
        img {
          width: 20px;
          // height: 20px;
          margin-right: 10px;
        }
      }
      .listItem-right {
        display: flex;
        align-items: center;
        // height: 24px;
        div {
          display: flex;
          align-items: center;
          color: #4a4a4a;
          width: 66px;
          // height: 24px;
          background: #eff4f8;
          border-radius: 12px;
          font-size: 13px;
          .bd-50 {
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background: #ffb000;
            margin: 0 5px 0 8px;
          }
          .color-green {
            background: #3ec06d;
          }
        }
        .color-red {
          display: inline-block;
          border-radius: 0;
          background: #ffffff;
          width: 140px;
          color: #ff0704;
        }
        img {
          width: 15px;
          margin-left: 6px;
        }
      }
    }
    .myName {
      border-bottom: 1px solid #f3f3f3;
    }
  }
  .exitButton {
    margin: 20px auto 0;
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 0;
    font-size: 17px;
    border-radius: 25px;
  }
}
</style>
