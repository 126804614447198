<template>
  <div class="feedBack">
    <img src="../../assets/my/kf@2x.png" alt />
    <!-- <p>任何问题，请添加客服微信，我们将第一时间为您解决。</p> -->
    <!-- <p>Any problem, please add customer service wechat, we will be the first time for you to solve.</p> -->
    <p>
      {{ $t("feed_back.content") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "feedBack",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.feedBack {
  // padding: 15px 15px 0;
  height: 100vh;
  background: #f8f8f8;
  position: relative;
  p {
    padding: 0 44px 0;
    position: absolute;
    top: 70px;

    // margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    line-height: 22px;
  }
}
</style>
