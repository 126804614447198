<template>
  <div class="my">
    <div class="top">
      <div class="avatar">
        <div class="a_left">
          <img src="../assets/new_my/touxiang.png" alt="" class="a_l_img" />
          <div class="a_l_box">
            <div class="a_l_b_item a_l_b_name">Claudia Ramos</div>
            <div class="a_l_b_item">UID:{{ userInfo.uid }}</div>
          </div>
        </div>
        <img
          @click="go('/setting')"
          src="../assets/new_my/setting.png"
          alt=""
          class="a_right"
        />
      </div>
    </div>

    <div class="purse" @click="go('/purse')">
      <div class="p_top">
        <div class="p_t_left">
          <img
            src="../assets/new_my/purse_icon.png"
            alt=""
            class="p_t_left_img"
          />
          <div class="p_t_left_item">
            {{ $t('my.title1') }}
          </div>
        </div>
        <img src="../assets/new_my/right_icon.png" alt="" class="p_t_right" />
      </div>
      <div class="p_bottom">
        <div v-for="(item, index) in infoList" :key="index">
          <div class="p_b_item" v-if="item.symbol != 'ETH'">
            <div class="p_b_i_top">
              {{ item.amount }}
            </div>
            <div class="p_b_i_bottom" v-if="item.symbol == 'BTC'" style="text-align:left;">
              {{ item.symbol }}
            </div>
            <div class="p_b_i_bottom" v-else>
              {{ item.symbol }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="electricity" @click="go('/newelectricity')">
      <div class="e_left">
        <img
          src="../assets/new_my/electricity_icon.png"
          alt=""
          class="e_left_img"
        />
        <div class="e_left_item">
            {{ $t('my.title2') }}
        </div>
      </div>
      <div class="e_right">
        <div class="e_r_left">
          {{ userInfo.powerFee }}
        </div>
        <img src="../assets/new_my/right_icon.png" class="e_r_right" />
      </div>
    </div>

    <div class="statistics">
      <div class="top">
        <div class="s_left">
          <img
            src="../assets/new_my/statistics_icon.png"
            alt=""
            class="s_left_img"
          />
          <div class="s_left_item">
            {{ $t('my.title3') }}
          </div>
        </div>
        <div class="s_right">
          <div class="s_r_left">
            <!-- Updated daily at 20:00 (utc+8) -->
          </div>
          <!-- <img src="../assets/new_my/right_icon.png" class="s_r_right" /> -->
        </div>
      </div>
      <div class="chart-box-body">
        <div
          ref="chartLineBox"
          :style="{ width: '100%', height: '100%' }"
        ></div>
      </div>
    </div>

    <div
      class="items"
      v-for="(item, index) in list"
      :key="index"
      @click="go(item.router)"
    >
      <div class="i_left">
        <img
          :src="require('../assets/new_my/' + item.src + '.png')"
          alt=""
          class="i_left_img"
        />
        <!-- <img :src="`../assets/new_my/${item.src}.png`" alt="" /> -->
        <div class="i_left_item">
          {{ item.title }}
        </div>
      </div>
      <img src="../assets/new_my/right_icon.png" class="i_right" />
    </div>
  </div>
</template>

<script>
import echarts from "echarts"
export default {
  name: "my",
  data() {
    return {
      infoList: [],
      info: "",
      userInfo: {},
      list: [
        {
          title: this.$t('my.title4'),
          src: "1",
          router: "/help",
        },
        {
          title: this.$t('my.title5'),
          src: "2",
          router: "/feedBack",
        },
        {
          title: this.$t('my.title6'),
          src: "3",
          router: "/about",
        },
      ],
      option: {
        grid: {
          top: "42px",
          left: "50px",
          right: "20px",
          bottom: "50px",
        },
        xAxis: {
          type: "category",
          axisLine: {
            lineStyle: {
              color: "#FFD528", //更改坐标轴颜色
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          nameTextStyle: {
            color: ["#4A4A4A"],
            fontWeight: 900,
            fontSize: 18, //更改坐标轴文字大小
          },
          axisLine: {
            lineStyle: {
              color: ["#FFD528"],
            },
          },
        },
        series: [
          {
            data: [],
            type: "line",
            color: ["#FFD528"],
          },
        ],
      },
    }
  },
  created() {
    this.getMeInforMation()
    this.getAssets()
    this.$nextTick(() => {
      this.getHomeList()
      this.drawLine()
    })
  },
  components: {},
  mounted() {},
  methods: {
    go(url) {
      this.$router.push(url)
    },
    getHomeList(flag) {
      this.$request
        .get("/user/mineList")
        .then(
          (res) => {
            if (res.code == 200) {
              let timeList = []
              let amountList = []
              let mineList = res.data //原数组
              //将原数组的index放入sort_num中，原数组的btn_id放入id里
              mineList.forEach(function(item, index) {
                item.newday = item.day.substring(5, 10)
                timeList.push(item.newday)
                amountList.push(item.mineralAmount)
              })
              this.chartLine.setOption({
                xAxis: {
                  type: "category",
                  data: timeList,
                },
                yAxis: {
                  type: "value",
                  name: "BTC",
                },
                series: [
                  {
                    data: amountList,
                  },
                ],
              })
              if (flag == false) {
                this.changeTimeFlag()
              }
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
    drawLine() {
      // 指定图表的配置项和数据
      this.chartLine = echarts.init(this.$refs.chartLineBox)
      // 使用刚指定的配置项和数据显示图表。
      this.chartLine.setOption(this.option)
    },
    // 获取资产信息
    getAssets() {
      this.$request
        .get("/user/assets")
        .then(
          (res) => {
            if (res.code == 200) {
              this.info = res.data.balance
              this.infoList = res.data.assets
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },

    // 获取用户信息
    getMeInforMation() {
      this.$request
        .get("/user/userInfo")
        .then(
          (res) => {
            if (res.code == 200) {
              this.userInfo = res.data
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch((err) => {
          this.$toast(err)
        })
    },
    //点击退出
    exit() {
      this.isShowExitView = true
    },
    //提现
    toTX(symbol) {
      this.$router.push({
        path: "/Withdrawal",
        query: {
          symbol: symbol,
        },
      })
    },
    //去交易记录
    gotoRecordList(symbol) {
      if (symbol != "") {
        this.$router.push({
          path: "/MentionRecord",
          query: {
            symbol: symbol,
          },
        })
      } else {
        this.$router.push({
          path: "/electricity",
          // query: {
          //   symbol: symbol
          // }
        })
      }
    },
    //确定退出
    sure() {
      localStorage.removeItem("token")
      this.$router.push("/login")
      this.isShowExitView = false
    },
    //取消退出
    cancel() {
      this.isShowExitView = false
    },
  },
}
</script>
<style scoped lang="scss">
.my {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-items: center;
  padding: 0 16px 80px;
  background: #f9f9f9 url("../assets/new_my/my_back.png") no-repeat top/100%;

  .top {
    width: 100%;
    // height: 300px;
    .avatar {
      width: 100%;
      padding: 74px 4px 0px 12px;
      display: flex;
      // flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .a_left {
        display: inline-flex;
        height: 60px;
        text-align: left;
        .a_l_img {
          width: 60px;
          height: 60px;
          border: 2px solid #ffffff;
          border-radius: 50%;
          margin-right: 12px;
        }
        .a_l_box {
          display: inline-flex;
          flex-direction: column;
          padding-top: 10px;
          .a_l_b_item {
            margin-bottom: 3px;
            font-size: 14px;
            color: #4a4a4a;
          }
          .a_l_b_name {
            font-family: DINPro, DINPro;
            font-weight: bold;
            font-size: 20px;
            color: #333333;
          }
        }
      }
      .a_right {
        width: 24px;
        height: 24px;
      }
    }
  }
  .purse {
    margin-top: 18px;
    width: 100%;
    height: 148px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 16px 0 20px;
    display: flex;
    flex-direction: column;

    .p_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .p_t_left {
        display: flex;
        align-items: center;
        .p_t_left_img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 14px;
        }
        .p_t_left_item {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
        }
      }
      .p_t_right {
        width: 6px;
        height: 10px;
      }
    }

    .p_bottom {
      margin-top: 22px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .p_b_item {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 16px;
        color: #a5a5a5;
        text-align: left;
        .p_b_i_top {
          margin-bottom: 3px;
          font-size: 18px;
          color: #4a4a4a;
        }
        .p_b_i_bottom {
          text-align: right;
        }
      }
    }
  }
  .electricity {
    margin-top: 10px;
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 16px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .e_left {
      display: flex;
      align-items: center;
      .e_left_img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 14px;
      }
      .e_left_item {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .e_right {
      display: flex;
      align-items: center;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: 700;
      font-size: 20px;
      color: #4a4a4a;
      .e_r_right {
        margin-left: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }
  .statistics {
    width: 100%;
    display: flex;
    flex-direction: column;
    .top {
      margin-top: 10px;
      width: 100%;
      height: 60px;
      background: #ffffff;
      border-radius: 8px 8px 0 0;
      padding: 0 16px 0 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .s_left {
        display: flex;
        align-items: center;
        .s_left_img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-right: 14px;
        }
        .s_left_item {
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .s_right {
        display: flex;
        align-items: center;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 20px;
        color: #4a4a4a;
        .s_r_left {
          width: 120px;
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
          font-weight: normal;
          font-size: 14px;
          color: #a5a5a5;
        }
        .s_r_right {
          margin-left: 10px;
          width: 10px;
          height: 10px;
        }
      }
    }

    .chart-box-body {
      width: 100%;
      height: 260px;
      background: #ffffff;
      // box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 8px 8px;
      margin: 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .items {
    margin-top: 10px;
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 16px 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .i_left {
      display: flex;
      align-items: center;
      .i_left_img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 14px;
      }
      .i_left_item {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: 700;
        font-size: 16px;
      }
    }
    .i_right {
      margin-left: 10px;
      width: 10px;
      height: 10px;
    }
  }
}
</style>
