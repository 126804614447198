<template>
  <div>
    <div class="Withdrawal">
      <!-- <div class="notice">
        <van-notice-bar
          color="#333333"
          background="rgba(248,222,77,0.3)"
          mode="link"
        >
          <template #left-icon>
            <img src="../assets/home/list.png" alt="" />
          </template>
          <template #default>无需手续费，兑换点卡直接购买产品 </template>
        </van-notice-bar>
      </div> -->
      <div class="tx">
        <div class="type">
          <div class="left">
            {{ $t('topup.title1') }}
          </div>
          <div class="right">
            <img src="../assets/new_my/op.png" alt="" />
            <span>{{ symbol }}</span>
          </div>
        </div>
        <!-- <div class="num-title">
          Recharge quantity
        </div>
        <div class="num">
          <van-field
            class="left"
            v-model="mentionCoinNumber"
            type="number"
            placeholder="最少0.001"
            :border="false"
          />
        </div> -->
        <div class="num-title">
            {{ $t('topup.title2') }}
        </div>
        <van-field
          style="width:100%;font-size: 15px;color: #333333!important;font-weight: bolder;"
          class="num"
          v-model="network"
          :title="$t('topup.title2')"
          readonly
          name="picker"
          :placeholder="$t('topup.title2')"
        />
          <!-- is-link icon属性 -->
        <!-- @click="showPicker = true" -->
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            :disabled="true"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <vue-qr
          :logoSrc="logoPath"
          :logoCornerRadius="10"
          :text="coinAddress"
          :size="160"
          :margin="2"
          style="border-radius:10px;margin: 30px 0 10px;"
        ></vue-qr>
        <div class="num-title">
          {{ $t('topup.title3') }}
        </div>
        <!-- <div class="num"> -->
        <van-cell-group inset>
          <van-field
            style="font-size: 15px;color: #333333!important;font-weight: bolder;"
            class="num"
            v-model="coinAddress"
            :placeholder="$t('topup.title3')"
            autosize
            type="textarea"
            :disabled="true"
          >
            <template #button>
              <img
                @click="copyToClipboard(coinAddress)"
                style="width:12px;margin-right:10px;"
                src="../assets/new_my/copy.png"
                alt=""
              />
            </template>
          </van-field>
        </van-cell-group>
        <!-- </div> -->
        <!-- <div class="ktye">支持划转本链bTC接受地址，一般1或3开头</div> -->
      </div>
      <div class="kj-sm">
        <div class="footer-title">
          <img
            style="width:15px;margin-right:7px;"
            src="../assets/new_my/error.png"
            alt=""
          />
          {{ $t('topup.Friendly.title') }}
        </div>
        <div class="footer-body">
          {{ $t('topup.Friendly.content') }}
          <br />
          <br />
          {{ $t('topup.Friendly.content2') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant"
import vueQr from "vue-qr/src/packages/vue-qr.vue"
import logoImg from "../assets/home/btc.png"

export default {
  name: "Withdrawal",
  data() {
    return {
      coinAddress: "", //提币地址
      network: "OP Mainnet", //网络
      mentionCoinNumber: "", //提币数量
      symbol: "USDT",
      showPicker: false,
      columns: [
        "OP Mainnet",
        "USDT-OKTC",
        "BNB Smart Chain/BSC",
        "USDT-KOPLUYA",
      ],
      logoPath: require("../assets/new_my/op.png"),
    }
  },
  components: {
    vueQr,
  },
  computed: {},
  mounted() {
    // this.symbol = this.$route.query.symbol
    this.getUserInfo()
  },
  methods: {
    async copyToClipboard(textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy)
        this.$toast("Content copied to clipboard")
      } catch (err) {
        this.$toast("Failed to copy to clipboard")
      }
    },
    onConfirm(value) {
      this.network = value
      this.showPicker = false
    },
    getUserInfo() {
      this.$request
        .post("/user/getAddress")
        .then(
          (res) => {
            if (res.code == 200) {
              this.coinAddress = res.data.address
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
    //提现
    withdrawal() {
      const params = {
        withdrawAmount: this.mentionCoinNumber,
        address: this.coinAddress,
        network: this.network,
        symbol: this.symbol,
      }
      this.$request
        .post("/user/withdraw", params)
        .then(
          (res) => {
            if (res.code == 200) {
              this.$toast.success({
                message: "提现成功",
                duration: 1000,
              })
              setTimeout(() => {
                this.$router.push({ path: "/my" })
              }, 1000)
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.Withdrawal {
  font-weight: bolder;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px 90px;
  background: #f8f8f8;

  .notice {
    width: 100%;
    padding: 13px 0 18px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    .van-notice-bar {
      font-size: 12px;
      padding: 0;
      font-weight: bolder;
      line-height: 40px;
      border-radius: 8px;
      padding-right: 5px;
    }
    img {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px;
    }
  }

  .tx {
    width: 100%;
    height: 500px;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    .type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        // align-items: center;
        vertical-align: middle;
        font-family: AppleSystemUIFont;
        font-size: 15px;
        color: #666666;
        line-height: 18px;
        text-align: left;
        font-style: normal;
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          margin-right: 6px;
        }
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 17px;
        color: #333333;
        line-height: 23px;
        text-align: left;
        font-style: normal;
      }
    }
    .num-title {
      font-family: AppleSystemUIFont;
      font-size: 15px;
      color: #666666;
      line-height: 18px;
      text-align: left;
      font-style: normal;
      margin-top: 20px;
    }
    .num {
      width: 100%;
      height: 52px;
      background: #f9f9f9;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 12px;
      margin-top: 10px;
      .left {
        width: 220px;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 24px;
        color: #333333;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        background: #f9f9f9;
        padding: 0;
        vertical-align: middle;
      }
      .right {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 15px;
        color: #f8b34d;
        line-height: 21px;
        text-align: left;
        font-style: normal;
        padding: 0 12px;
      }
    }
    .ktye {
      width: 100%;
      margin-top: 10px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 13px;
      color: #666666;
      line-height: 18px;
      text-align: right;
      font-style: normal;
      padding-right: 4px;
    }
  }
  .kj-sm {
    text-align: left;
    width: 100%;
    margin-top: 10px;
    padding: 10px 16px 13px 20px;
    background: #fefcf4;
    height: 150px;
    border-radius: 8px;

    .footer-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 10px;
      font-size: 14px;
      color: #333333;
    }
    .footer-body {
      width: 100%;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
font-weight: normal;
font-size: 13px;
color: #4A4A4A;
    }
  }
}

::placeholder {
  font-size: 15px;
  font-size: 24px;
  color: #999999;
  font-weight: bolder;
  vertical-align: middle;
  line-height: 32px;
}
input {
  vertical-align: middle;
  line-height: 32px;
}
</style>

<style lang="scss">
.van-field__control:disabled {
  color: #333333 !important;
}
</style>
