module.exports = {
  tabbar: {
    Home: "首页",
    Mining: "矿机",
    kjOrder: "订单",
    Me: "我的",
  },
  titleList: {
    Home: '首页',
    login: '登陆',
    sign: '注册',
    kjList: '矿机列表',
    kjDetails: '矿机详情',
    kjOrder: '矿机订单',
    kjOrderDetails: '矿机订单详情',
    kjBuy: '购买矿机',
    more: '了解更多',
    my: '我的',
    purse: '钱包',
    help: '帮助中心',
    about: '关于我们',
    setting: '设置',
    feedBack: '联系客服',
    topup: '钱包 充值',
    withdrawal: '钱包 提现',
    newelectricity: '电费',
    electricitytopup: '电费充值'
  },
  setting: {
    title1: '基本信息',
    title2: '帐户设置',
    title2_content: '邮箱',
    title3: '系统设置',
    title3_content: '语言切换',
    log_out: '退出登录',
    log_out_title: '退出',
    log_out_content: '退出登录将无法实时查看挖矿状态，您确定要退出吗？',
    log_out_button1: '确认',
    log_out_button2: '取消',
  },
  home: {
    notice: '有货，现在可以买到',
    title1: '每日预计收入（BTC/T）',
    title2: '矿机套餐',
    title3: '历史在线率',
  },
  kjList: {
    notice: '所购矿机永久归属用户，享受超长周期挖矿收',
    title3: '历史在线率',
    payButton: "立即下单"
  },
  kjBuy: {
    left1: "清关费",
    left2: "上下价费",
    left3: "转场运输费",
    right: "unit",
    typeTitle: "选择支付方式",
    type1: "Datarun钱包账户",
    type2: "FSPOO矿池打币",
    zj: "总计",
    ye: "可用余额",
    payButton: "支付",
    syt: {
      title: "收银台",
      left: "钱包",
      right: "余额",
      payButton: "立即支付"
    },
    dialog: "是否确认购买？",
    dialog2: "钱包余额不足，是否充值？",
    syt2: {
      font: "支付成功",
      font2: "钱包支付",
      button: "完成",
    }
  },
  kjOrder: {
    title: '订单',
    content: "查看订单产出记录和设备信息",
    left1: "总产量(BTC)",
    left2: "算力",
    left3: "功率",
    left4: "设备台数",
    right4: "台",
    left5: "设备状态",
    state1: "停机",
    state2: "在挖",
    state3: "待上线",
  },
  kjOrderDetail: {
    tab1: "产出记录",
    tab2: "设备信息",
    number: "设备编码",
    adrTitle: "设备地址",
    adr: "美国，阿肯色州",
  },
  my: {
    title1: '钱包',
    title2: '电费',
    title3: '数据统计',
    title4: '帮助中心',
    title5: '联系客服',
    title6: '关于我们',
  },
  help: {
    title: '更多常见问题',
    one: {
      title: '托管费如何扣除',
      content: '托管费将于每日产出中自动扣除，后台每日产出数据为实际到账，即已扣除托管费。'
    },
    two: {
      title: '关于每日产出和电费扣除时间？',
      content: '矿机每日产出及电费扣除时间统一在18:00PM(GMT+8)'
    },
    three: {
      title: '关于提币时间和手续费？',
      content: '1.提币T+0，工作日当天17:00前提币，当天到；工作日17:00后提币，第二个工作日到；如遇节假日产出照常发放。 ',
      content2: '2.最低起提额为0.00054BTC/次，手续费为0.00011BTC/次（为了确保提币效率，或将根据BTC网络拥堵情况适时调整）。'
    },
    four: {
      title: '电费余额不足以扣费会出现什么情况？',
      content: '若矿机剩余预存电费不足以覆盖2天所需电费，则矿机将停止运行产出。在充值电费后若预存金额足以涵盖2天所需电费，矿机将在次日17:00PM(GMT+8)前恢复运行。用户请自行监控电费余额，避免影响机器正常产出，造成不必要损失。'
    },
    five: {
      title: '用户可否转让或提走矿机？',
      content: '1.凡在平台购买并托管矿机的用户，购买后若不想继续合作，平台支持用户转让矿机给其他已在本平台注册的用户，转让价格将由买卖双方协定，平台客服协助完成转让流程，具体细节可咨询您的销售客服。',
      content2: '2.平台支持用户提走矿机，用户可使用矿场合作物流运输，相关费用由用户自行承担，具体细节可咨询您的销售客服。'
    }
  },
  disclaimer: {
    title: '免责声明',
    one: '1.按照协议规定，一旦产品购买完成，将不支持更换或退款',
    two: '2.不对以下不可控风险所造成的损失负责：不能预见、不能避免或不能克服的客观事件，包括自然灾害如洪水、火山爆发、地震、山崩、火灾、被政府部门评定为百年不遇级别以上的风暴和恶劣气候等，政府行为和政府指令，城市级别的电网供电事故，以及社会异常事件如战争、罢工、动乱等。',
    three: '3.静态产出是根据当前算力难度静态推算，未考虑矿池费率，仅供参考，本平台对BTC产出不作承诺。DataRun提供的矿机托管为真实服务，真实服务由于网络、矿机功率等不稳定因素会导致波动，本平台不承诺100%稳定运行。'
  },
  output: {
    title: '收益说明',
    content: "矿机每天的收益与全网的算力有关，将根据市场难度波动而变化，及链上算力变化而浮动，可以參考BitFuFu Pool理论收益计算。当前静态产出= 算力数 * 单T日理论收益 "
  },
  purchase: {
    title: '购买及托管流程',
    one: '根据DataRun网站付款指示完成付款流程（注意：目前仅接受TRC20-USDT支付）',
    two: '下单后T+7个自然日（现货）或T+45个自然日（期货）机器完成上架，次日起开始产出。',
    three: '机器上架期间请自行在"电费"内预充值相应金额，避免影响机器正常产出而造成不必要损失。'
  },
  fee: {
    title: '费用说明',
    one: '1. 矿机费用:  8500USDT/台 (现货)， 7988USDT/台 (期货)， 价格包含清关费，物流费和上架费。',
    two: '2. 电费:  0.05USDT/Kwh, 电费=(0.05 * 功率 / 1000 * 24 + 机位费) * 天数 * 台数',
    three: '3. 托管费:  每日产出的20%， 托管费= 20% * 算力 * 日理论收益(BTC/T) * 台数 '
  },
  product: {
    title: '产品细节',
    one: {
      left: '功能',
      right: 'BTC SHA256 风冷矿机'
    },
    two: {
      left: '算力',
      right: ''
    },
    three: {
      left: '功率',
      right: ''
    },
    four: {
      left: '静态日产出',
      right: ''
    },
    five: {
      left: '参考关机价',
      right: ''
    },
    six: {
      left: '电价',
      right: ''
    },
    seven: {
      left: '管理费',
      right: '20% of daily output'
    },
    eight: {
      left: '上架期',
      right: 'T+7'
    },
    nine: {
      left: '生效日期',
      right: 'T+7 or T+45'
    },
    ten: {
      left: '机位费',
      right: '0.7USDT/Unit/Day'
    },
    eleven: {
      left: '矿场',
      right: '阿肯色州'
    }
  },
  product_title: {
    highlights: "优势",
    price: {
      title: "价格包含",
      one: "关税",
      two: "物流费",
      three: "上架费"
    }
  },
  feed_back: {
    content: '如果您有任何问题，请在 Telegram 上添加我们的客户服务。 我们将尽快为您提供帮助。'
  },
  about: {
    title: '关于我们',
    content: 'DATARUN 是一个区块链挖矿服务平台，其挖矿设施位于美国阿肯色州。 总容量为 33 MW（兆瓦），支持 10,000 多台比特币矿机。',
    content2: '团队拥有 4 年的北美矿场运营经验，在专业机器维护和获取受监管电力资源方面具有优势。DATARUN 致力于为全球客户提供一站式挖矿解决方案，包括采购、托管和维护服务，旨在全面解决传统挖矿行业的痛点，确保客户无忧挖矿体验。'
  },
  purse: {
    title: "帐户余额(USDT)",
    left: "充值",
    right: "提现",
    title2: "资产列表"
  },
  topup: {
    title1: '充值',
    title2: '网络',
    title3: 'USDT 充值地址',
    Friendly: {
      title: '友情提示',
      content: '这是一个充值地址，您可以从其他钱包或平台转入 USDT。',
      content2: '注意：此地址仅支持接收OP链资产。 其他资产的转账将不计入并无法收回。',
    }
  },
  withdrawal: {
    notice: '无服务费，直接购买产品',
    coin: '提币',
    number: '数量',
    least: '至少提取',
    balance: '可提取余额：',
    net: '网络',
    adr: '提现地址',
    supports: '支持转账至BTC接收地址，通常以1或3开头。',
    friendly: {
      title: '友情提示：',
      content: '1. 请仔细检查您的提现地址。 如果您的提现地址有误，您将无法取回您的资产。 由此造成的损失由您自行承担。',
      content2: '2. 17:00（UTC+8）之前提交的提币将在当天内审核。',
      content3: '3. 17:00（UTC+8）之后提交的提币将在第二天进行审核。',
    },
    actual: '实际到账',
    fee: '手续费',
    withdrawn: '提现',

  },
  electricity: {
    title: "电费余额",
    button: "充值",
    listTitle: "变动记录",
    listType: "充值",
    listType2: "扣除",
    listFont: "电费",
    rechargeNum: "充值数量",
    place: "输入要充值的金额",
    ktyeLeft: "你有",
    ktyeRight: "可用",
    button2: "确认充值",
    dialog: "是否确认充值？",
    cancel: "Cancel",
    confirm: "Confirm"
  }

}