<template>
  <div class="kj">
    <div class="body">
      <!-- <div class="item" v-for="(item, index) in kjList" :key="index"> -->
      <div class="item">
        <div class="item_top">
          <img src="../assets/mill/kj.png" alt="" />
          <span>{{ kjList.machineName }}</span>
        </div>
        <div class="item_content">
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left1") }}
            </div>
            <div class="right">{{ kjList.totalOut }}</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left2") }}
            </div>
            <div class="right">{{ kjList.calculation }}H/s</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left3") }}
            </div>
            <div class="right">{{ kjList.power }}/h</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left4") }}
            </div>
            <div class="right">
              {{ kjList.amount }} {{ $t("kjOrder.right4") }}
            </div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left5") }}
            </div>
            <div v-if="kjList.status == 0" class="right last_right">
              {{ $t("kjOrder.state1") }}
            </div>
            <div
              v-else-if="kjList.status == 1"
              class="right last_right last_right2"
            >
              {{ $t("kjOrder.state2") }}
            </div>
            <div
              v-else-if="kjList.status == 2"
              class="right last_right last_right3"
            >
              {{ $t("kjOrder.state3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabs
      class="list"
      v-model:active="active"
      @click-tab="onClickTab"
      sticky
      shrink
    >
      <van-tab :title="$t('kjOrderDetail.tab1')">
        <div
          class="list_item"
          v-for="(item, index) in equipmentList"
          :key="index"
        >
          <div class="l_i_left">
            <div class="title">{{ item.symbol }}</div>
            <div class="date">{{ item.ctime }}</div>
          </div>
          <div class="l_i_right">{{ item.mineralAmount }}</div>
        </div>
      </van-tab>
      <van-tab
        :title="$t('kjOrderDetail.tab2')"
        v-if="
          this.kjList.machineNoList != undefined &&
            this.kjList.machineNoList != null &&
            this.kjList.machineNoList.length > 0
        "
      >
        <div
          class="list_item2"
          v-for="(item, index) in kjList.machineNoList"
          :key="index"
        >
          <div class="item2_item">
            <div class="l_i_left">{{ $t("kjOrderDetail.number") }}</div>
            <div class="l_i_right">{{ item }}</div>
          </div>
          <div class="item2_item">
            <div class="l_i_left">{{ $t("kjOrderDetail.adrTitle") }}</div>
            <div class="l_i_right">{{ $t("kjOrderDetail.adr") }}</div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "kjOrder",
  data() {
    return { equipmentList: [], kjList: {}, active: "" }
  },
  created() {
    this.getKjList()
  },
  methods: {
    onClickTab(e) {
      console.log(e)
    },
    getKjList() {
      this.$request
        .get("/user/dailyMineRecord")
        .then(
          (res) => {
            if (res.code == 200) {
              this.equipmentList = res.data
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {
    this.kjList = JSON.parse(window.history.state.kjList)
  },
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kj {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  height: auto;
  overflow: auto;
  .body {
    width: 100%;
    // margin-top: -80px;
    padding: 30px 20px 20px;
    .item {
      width: 100%;
      height: 220px;
      margin-bottom: 10px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .item_top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        img {
          width: 32px;
          margin: 0 8px 0 15px;
        }
        border-bottom: 1px solid #ededed;
      }
      .item_content {
        height: 170px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-self: ;
        .i_c_item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: 600;
          border-radius: 8px;
          .left {
            font-size: 14px;
            color: #999999;
          }
          .right {
            font-size: 14px;
            color: #333333;
          }
          .last_right {
            width: 48px;
            height: 24px;
            line-height: 24px;
            border-radius: 2px;
            background: #ffe6df;
            color: #ff3500;
          }
          .last_right2 {
            color: #03b980;
            background: #e5f8f2;
          }
          .last_right3 {
            background: #e4efff;
            color: #0068ff;
          }
        }
      }
    }
  }
  .list {
    padding: 20px;
    .list_item {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      background: #fff;
      text-align: left;
      border-radius: 8px;
      margin-top: 12px;
      font-weight: 600;
      .l_i_left {
        display: flex;
        flex-direction: column;
        align-content: space-between;
        .title {
          font-size: 18px;
          color: #4a4a4a;
          margin-bottom: 4px;
        }
        .date {
          font-size: 14px;
          color: #999999;
        }
      }
      .l_i_right {
        font-size: 16px;
        color: #333333;
      }
    }
    .list_item2 {
      width: 100%;
      height: 80px;
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: #fff;
      text-align: left;
      border-radius: 8px;
      margin-top: 12px;
      font-weight: 600;
      .item2_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .l_i_left {
          font-size: 14px;
          color: #999999;
        }
        .l_i_right {
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
<style lang="scss">
.van-tabs__nav {
  background: #f9f9f9;
}
.van-tabs__line {
  background: #f8de4d;
}
.van-tab {
  font-weight: 600;
  font-size: 16px;
  color: #bbbbbb;
}
.van-tab--active {
  font-size: 18px;
  color: #333333;
}
</style>
