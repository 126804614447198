<template>
  <div class="kj">
    <div class="top">
      <div class="title">{{ $t("kjOrder.title") }}</div>
      <div class="content">{{ $t("kjOrder.content") }}</div>
    </div>
    <div class="body">
      <div
        class="item"
        v-for="(item, index) in kjList"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="item_top">
          <img src="../assets/mill/kj.png" alt="" />
          <span>{{ item.machineName }}</span>
        </div>
        <div class="item_content">
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left1") }}
            </div>
            <div class="right">{{ item.totalOut }}</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left2") }}
            </div>
            <div class="right">{{ item.calculation }}H/s</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left3") }}
            </div>
            <div class="right">{{ item.power }}/h</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left4") }}
            </div>
            <div class="right">{{ item.amount }} {{ $t("kjOrder.right4") }}</div>
          </div>
          <div class="i_c_item">
            <div class="left">
              {{ $t("kjOrder.left5") }}
            </div>
            <div v-if="item.status == 0" class="right last_right">
              {{ $t("kjOrder.state1") }}
            </div>
            <div
              v-else-if="item.status == 1"
              class="right last_right last_right2"
            >
              {{ $t("kjOrder.state2") }}
            </div>
            <div
              v-else-if="item.status == 2"
              class="right last_right last_right3"
            >
              {{ $t("kjOrder.state3") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "kjOrder",
  data() {
    return { bannerList: [], kjList: [], mobile: "" }
  },
  created() {
    this.getKjList()
  },
  methods: {
    toDetails(e) {
      console.log("e", JSON.stringify(e))
      this.$router.push({
        path: "/kjOrderDetails",
        name: "kjOrderDetails",
        state: { kjList: JSON.stringify(e) },
      })
    },
    getKjList() {
      this.$request
        .get("/user/userMachineList")
        .then(
          (res) => {
            if (res.code == 200) {
              this.kjList = res.data
            } else {
              this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {},
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kj {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  height: auto;
  overflow: auto;
  .top {
    width: 100%;
    height: 184px;
    color: #333333;
    background: url("../assets/order/top.png") no-repeat;
    background-size: cover;
    font-weight: 600;
    text-align: left;
    padding-left: 20px;
    .title {
      font-size: 18px;
      padding: 26px 0 6px;
    }
    .content {
      font-size: 14px;
    }
  }
  .body {
    width: 100%;
    margin-top: -80px;
    padding: 0 20px 20px;
    .item {
      width: 100%;
      height: 220px;
      margin-bottom: 10px;
      background: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .item_top {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        img {
          width: 32px;
          margin: 0 8px 0 15px;
        }
        border-bottom: 1px solid #ededed;
      }
      .item_content {
        height: 170px;
        width: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-self: ;
        .i_c_item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: 600;
          border-radius: 8px;
          .left {
            font-size: 14px;
            color: #999999;
          }
          .right {
            font-size: 14px;
            color: #333333;
          }
          .last_right {
            // width: 48px;
            padding: 0 4px;
            height: 24px;
            line-height: 24px;
            border-radius: 2px;
            background: #ffe6df;
            color: #ff3500;
          }
          .last_right2 {
            color: #03b980;
            background: #e5f8f2;
          }
          .last_right3 {
            background: #e4efff;
            color: #0068ff;
          }
        }
      }
    }
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
