import { createI18n } from "vue-i18n";
import { Locale } from 'vant';

import vant_en from "vant/es/locale/lang/en-US";
import vant_zh from "vant/es/locale/lang/zh-CN";
import zh from "./zh"   // 中文
import en from "./en"   // 英文

// 准备翻译的语言环境信息
const messages = {
    'en': {
        ...en,
        ...vant_en
    },
    'zh': {
        ...zh,
        ...vant_zh
    }
}
// 默认语言 - 
const default_lang = "en"

Locale.use(localStorage.getItem("currentLanage") || default_lang, messages[localStorage.getItem("currentLanage") || default_lang])

const i18n = createI18n({
    legacy: true,
    locale: localStorage.getItem("currentLanage") || default_lang,
    globalInjection: true,  // 全局模式，可以直接使用 $t
    messages: messages,
    silentTranslationWarn: true

})

export default i18n //对外暴露 i18n, 在 main.js 中挂载
