<template>
  <div class="view-body">
    <router-view class="view-box" v-wechat-title="$route.meta.title" />
    <v-footer v-if="tabType"></v-footer>
  </div>
</template>

<script>
import footTabbar from "./components/footTabbar";
export default {
  data() {
    return {
      tabType: true
    };
  },
  watch: {
    $route(e) {
      if (e.name == "home" || e.name == "kjList" || e.name == "kjOrder" || e.name == "my") {
        this.tabType = true;
      } else {
        this.tabType = false;
      }
    }
  },
  components: {
    "v-footer": footTabbar
  },
  created() {},
  mounted() {},
  filters: {},
  methods: {}
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.view-body {
  width: 100%;
  height: auto;
  overflow: hidden;
  .view-box {
    .advantage-header {
      margin: 25px 0 15px;
      font-size: 17px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      display: flex;
      align-items: center;
      .advantage-header-title {
        margin-right: 5px;
        display: inline-block;
        width: 6px;
        height: 20px;
        background: #ffcd3a;
        border-radius: 3px;
      }
    }
  }
}
::placeholder {
  font-size: 16px;
  color: #d7d8e1;
}
::-webkit-input-placeholder {
  font-size: 16px;
}

/* Firefox 4-18 */
:-moz-placeholder {
  font-size: 16px;
}

/* Firefox 19-50 */
::-moz-placeholder {
  font-size: 16px;
}

/* - Internet Explorer 10–11
- Internet Explorer Mobile 10-11 */
::-ms-input-placeholder {
  font-size: 16px;
}

/* Edge (also supports ::-webkit-input-placeholder) */
::-ms-input-placeholder {
  font-size: 16px;
}
</style>
