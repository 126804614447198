module.exports = {
    tabbar: {
        Home: "Home",
        Mining: "Mining",
        kjOrder: "Orders",
        Me: "Me",
    },
    titleList: {
        Home: 'Home',
        login: 'login',
        sign: 'sign',
        kjList: 'List of miners',
        kjDetails: 'Miner details',
        kjOrder: 'Order of miners',
        kjOrderDetails: 'Miner Order details',
        kjBuy: 'Buying mining machine',
        more: 'Learn more',
        my: 'Me',
        purse: 'purse',
        help: 'Help Center',
        about: 'About Us',
        setting: 'setting',
        feedBack: 'Contact customer service',
        topup: 'topup',
        withdrawal: 'withdrawal',
        newelectricity: 'Electric',
        electricitytopup: 'Electric charge'
    },
    setting: {
        title1: 'Basic information',
        title2: 'Account setting',
        title2_content: 'mailbox',
        title3: 'System setting',
        title3_content: 'Language switching',
        log_out: 'Log out',
        log_out_title: 'quit',
        log_out_content: 'Logout login will not be able to view the mining status in real time, are you sure you want to log out?',
        log_out_button1: 'confirm',
        log_out_button2: 'Cancel',
    },
    home: {
        notice: 'in stock, available now',
        title1: 'Estimated Daily Revenue (BTC/T)',
        title2: 'Mining Machine Packages',
        title3: 'Historical Online Rate',
    },
    kjList: {
        notice: 'Purchased Mining Machines Permanently Belong to Users, Ensuring Long- Term Mining Profits',
        title3: 'Historical Online Rate',
        payButton: "Order now"
    },
    kjBuy: {
        left1: "Tariff",
        left2: "Deployment Fee",
        left3: "Logistics Fee",
        right: "unit",
        typeTitle: "Payment Method",
        type1: "Datarun Wallet",
        type2: "FSPOO Mining Pool Coin",
        zj: "Total Amount",
        ye: "Balance",
        payButton: "Pay Now",
        syt: {
            title: "Buy Now",
            left: "Wallet",
            right: "Balance",
            payButton: "Pay Now"
        },
        dialog: "Whether to confirm the purchase?",
        dialog2: "Insufficient wallet balance would you like to top up?",
        syt2: {
            font: "Payment Successful",
            font2: "Wallet payment",
            button: "Completed",
        }
    },
    kjOrder: {
        title: 'Order',
        content: "Output and miner's information",
        left1: "Total Output(BTC)",
        left2: "Hashrate",
        left3: "Power",
        left4: "Unit",
        right4: "unit",
        left5: "Miner Status",
        state1: "Offline",
        state2: "Online",
        state3: "To be activated",
    },
    kjOrderDetail: {
        tab1: "Output",
        tab2: "Miner Details",
        number: "Code",
        adrTitle: "Address",
        adr: "Arkansas, USA",
    },
    my: {
        title1: 'Wallet',
        title2: 'Electricity Fees',
        title3: 'Data Statistics',
        title4: 'Help Center',
        title5: 'Contact Customer Service',
        title6: 'About Us',
    },
    help: {
        title: 'More frequently asked Questions',
        one: {
            title: 'How are hosting fees deducted?',
            content: 'Hosting fees are automatically deducted from the daily earnings. The backend displays the actual credited earnings, which are net of hosting fees.'
        },
        two: {
            title: ' When are daily earnings and electricity fees deducted?',
            content: 'Daily earnings and electricity fees are deducted at 18:00 PM (GMT+8) each day.'
        },
        three: {
            title: ' What about withdrawal times and fees?',
            content: '1. Withdrawals are processed on a T+0 basis. Withdrawals made before 17:00 (GMT+8) on working days will be credited the same day, while withdrawals made after 17:00 will be credited the next working day. Earnings are distributed as usual during holidays.',
            content2: '2. The minimum withdrawal amount is 0.00054 BTC per transaction, with a fee of 0.00011 BTC per transaction (fees may be adjusted based on BTC network congestion to ensure withdrawal efficiency).'
        },
        four: {
            title: 'What happens if the electricity fee balance is insufficient?',
            content: 'If the pre-deposited electricity fee is insufficient to cover two days of required fees, the mining machine will stop operating and producing. Once the pre-deposited amount is sufficient to cover at least two days of electricity fees, the machine will resume operations by 17:00 PM (GMT+8) the following day. Users are responsible for monitoring their electricity fee balance to avoid disruptions in production and unnecessary losses.'
        },
        five: {
            title: 'Can users transfer or withdraw their mining machines?',
            content: '1. Users who purchase and host mining machines through the platform can transfer their machines to other registered users on the platform if they no longer wish to continue the cooperation. The transfer price will be negotiated between the buyer and seller, and the platform’s customer service will assist with the transfer process. Please consult your sales representative for details.',
            content2: '2. Users can withdraw their mining machines. The platform supports using partner logistics for transportation, with related costs borne by the user. Please consult your sales representative for more details. '
        }
    },
    disclaimer: {
        title: 'Disclaimer',
        one: '1. According to the agreement, once the purchase is completed, no exchanges or refunds will be supported.',
        two: '2.The platform is not liable for any results caused by uncontrollable risks, including but not limited to unforeseen, unavoidable, or insurmountable objective events, such as natural disasters (e.g., floods, volcanic eruptions, earthquakes, landslides, fires, storms, or higher risk weather events), government actions or directives, city-level power grid outages, and abnormal social events such as wars, strikes, riots, etc.',
        three: '3.The static output is a reference estimate based on the current mining difficulty and does not account for mining pool fees. DataRun makes no guarantees on BTC output. DataRun provides real hosting services, and due to instability factors such as network fluctuations and machine performance, the platform does not guarantee 100% s table operation. '
    },
    output: {
        title: 'Output  Explanation',
        content: "Daily output is influenced by the network's overall hashrate and fluctuates with market difficulty. Please refer to the BitFuFu Pool static daily revenue for estimates. Current Static Output = Hashrate * Static Daily Revenue/T."
    },
    purchase: {
        title: 'Purchase and Hosting',
        one: '1.Complete the payment process according to the instructions on the DataRun website (Note: Currently, only TRC20-USDT is accepted).',
        two: '2.Machines will be deployed within T+7 calendar days (in-stock) or T+45 calendar days (pre-order) after placing an order, and mining will commence the following day.',
        three: '3. During the machine deployment period, please ensure to deposit the required amount under "Electricity Fees" to avoid disruptions in normal operation and unnecessary losses.'
    },
    fee: {
        title: 'Fee Description',
        one: '1. Miner Price: 8500USDT/unit (in-stock), 7988USDT/unit (pre-order), including tariff, logistics fee and deployment fee.',
        two: '2. Electricity Charge: 0.05USDT/Kwh, Electricity Charge=(0.05 * Power / 1000 * 24 + Service Fee) * Days * Units',
        three: '3. Hosting Fee: 20% of daily output, Hosting Fee= 20% * Hashrate * Static Daily Revenue(BTC/T) * Units'
    },
    product: {
        title: 'Product details',
        one: {
            left: 'Function',
            right: 'BTC SHA256 Air-cooling Miner'
        },
        two: {
            left: 'Hashrate',
            right: ''
        },
        three: {
            left: 'Power Consumption',
            right: ''
        },
        four: {
            left: 'Estimated Daily Output',
            right: ''
        },
        five: {
            left: 'Reference Shutdown Coin Price',
            right: ''
        },
        six: {
            left: 'Electricity Fee',
            right: ''
        },
        seven: {
            left: 'Hosting Fee Share',
            right: '20% of daily output'
        },
        eight: {
            left: 'Deployment Date',
            right: 'T+7'
        },
        nine: {
            left: 'Commencement Date',
            right: 'T+7 or T+45'
        },
        ten: {
            left: 'Service Fee',
            right: '0.7USDT/Unit/Day'
        },
        eleven: {
            left: 'Mining Farm',
            right: 'ARKANSAS'
        }
    },
    product_title: {
        highlights: "Highlights"
    },
    feed_back: {
        content: 'If you have any problems, please add our customer service on Telegram. We will assist you as soon as possible.'
    },
    about: {
        title: 'About Us',
        content: 'DATARUN is a blockchain mining service platform with mining facilities located in Arkansas, USA. The total capacity is 33 MW (megawatts), supporting over 10,000 Bitcoin mining machines.',
        content2: 'The team has 4 years of North American mining farm operation experience, with expertise in professional machine maintenance and ac- cess to regulated power resources. Dedicated to providing global customers with one-stop mining solutions, including purchasing, hosti- ng, and maintenance services, DATARUN aims to comprehensively address traditional mining industry pain points, ensuring a worry-free mining experience for customers.'
    },
    purse: {
        title: "Account Balance（USDT)",
        left: "Recharge",
        right: "Withdraw",
        title2: "Asset List"

    },
    topup: {
        title1: 'Recharge Currency',
        title2: 'Network',
        title3: 'USDT Recharge Address',
        Friendly: {
            title: 'Friendly Reminder',
            content: 'This is a recharge address where you can transfer USDT from other wallets or platforms.',
            content2: 'Note: This address only supports receiving OP assets. Transfers of other assets will not be credited and cannot be recovered.',
        }
    },
    withdrawal: {
        notice: 'No service fee, directly purchase products',
        coin: 'Coin',
        number: ' Quantity ',
        least: 'At least the withdrawn',
        balance: 'Available Balance: ',
        net: 'network',
        adr: 'Address',
        supports: 'Supports transfer to native BTC receiving addresses, usually starting with 1 or 3.',
        friendly: {
            title: 'Friendly Reminder：',
            content: '1.Double-check your withdrawal address carefully. Withdrawn assets cannot be retrieved if sent to an incorrect address. Any resulting loss will be the responsibility of the customer.',
            content2: '2.Withdrawals submitted before 17:00 (UTC+8) will be reviewed within the same day.',
            content3: '3.Withdrawals submitted after 17:00 (UTC+8) will be reviewed the next day.',
        },
        actual: 'Receive Amount',
        fee: 'fee',
        withdrawn: 'withdrawn',

    },
    electricity: {
        title: "Electricity Fee Balance",
        button: "Recharge",
        listTitle: "Deduction Records",
        listType: "recharge",
        listType2: "Deduct",
        listFont: "Electricity Fee",
        rechargeNum: "Recharge Amount",
        place: "Enter the amount to be transferred",
        ktyeLeft: "You have ",
        ktyeRight: "available",
        button2: "Confirm Recharge",
        dialog: "Whether to confirm the recharge?"
    }
}