<template>
  <div>
    <div class="kjBuy">
      <div class="kj">
        <div class="item1">
          <img class="item1_left" src="../assets/detail/kj.png" alt="" />
          <div class="item1_right">
            <div class="item1_right_title">
              {{ routerParams.name }}
            </div>
            <div class="item1_right_content">
              <div class="item1_right_content_left">
                {{ routerParams.price }}
                <span>USDT/{{ $t("kjBuy.right") }}</span>
              </div>
              <div class="item1_right_content_right">
                <van-stepper
                  v-model="amount"
                  input-width="30px"
                  button-size="16px"
                  @change="textChange"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="item2">
          <div class="item2_left">{{ $t("kjBuy.left1") }}</div>
          <div class="item2_right">
            {{ routerParams.qgCost || "0USDT" }}/{{ $t("kjBuy.right") }}
          </div>
        </div>
        <div class="item2">
          <div class="item2_left">{{ $t("kjBuy.left2") }}</div>
          <div class="item2_right">
            {{ routerParams.onoffCost || "0USDT" }}/{{ $t("kjBuy.right") }}
          </div>
        </div>
        <div class="item2">
          <div class="item2_left">{{ $t("kjBuy.left3") }}</div>
          <div class="item2_right">
            {{ routerParams.transferCost || "0USDT" }}/{{ $t("kjBuy.right") }}
          </div>
        </div>
      </div>
      <!-- <div class="type">
        <div class="title">
          {{ $t("kjBuy.typeTitle") }}
        </div>
        <div class="content">
          <div class="left">
            {{ $t("kjBuy.type1") }}
          </div>
          <div class="right">
            {{ $t("kjBuy.type2") }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="footer">
      <div class="left">
        <div>
          {{ $t("kjBuy.zj") }}
          <span style="font-size: 22px; margin-left: 4px">
            {{ total }}
          </span>
          USDT
        </div>
        <div class="sxf">
          {{ $t("kjBuy.ye") }}
          <span style="margin-left: 4px">{{ usdtBalance }}</span>
          USDT
        </div>
      </div>
      <van-Button
        @click="payShow"
        class="right"
        :class="{ disabled: canWithdrawal }"
        :disabled="!canWithdrawal"
      >
        {{ $t("kjBuy.payButton") }}
      </van-Button>
    </div>
    <van-action-sheet
      v-model:show="show"
      :title="!payState ? $t('kjBuy.syt.title') : ''"
    >
      <div class="checkstand" v-if="!payState">
        <div class="money">
          {{ total }}
          <span> USDT </span>
        </div>
        <div class="list">
          <div class="left">
            <img src="../assets/new_my/purse_icon.png" alt="" />
            {{ $t("kjBuy.syt.left") }}
          </div>
          <div class="right">
            {{ $t("kjBuy.syt.right") }}{{ usdtBalance }}USDT
          </div>
        </div>
        <van-Button
          @click="close(true)"
          class="pay_button"
          :class="{ disabled: canWithdrawal }"
          :disabled="!canWithdrawal"
          :loading="loading"
        >
          {{ $t("kjBuy.syt.payButton") }}
        </van-Button>
      </div>
      <div class="checkstand" v-else>
        <img src="../assets/detail/success.png" alt="" class="ok" />
        <div class="font">
          {{ $t("kjBuy.syt2.font") }}
        </div>
        <div class="money">
          {{ total }}
          <span> USDT </span>
        </div>
        <div class="font2">
          {{ $t("kjBuy.syt2.font2") }}
        </div>
        <van-Button @click="payShow(2)" class="pay_button">
          {{ $t("kjBuy.syt2.button") }}
        </van-Button>
      </div>
    </van-action-sheet>
    <van-dialog
      class="dia"
      v-model:show="diaShow"
      @confirm="pay"
      show-cancel-button
    >
      {{ $t("kjBuy.dialog") }}
    </van-dialog>
    <van-dialog
      class="dia"
      v-model:show="diaShow2"
      @confirm="goRecharge"
      show-cancel-button
    >
      {{ $t("kjBuy.dialog2") }}
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant"
export default {
  name: "Withdrawal",
  data() {
    return {
      // coinAddress: "", //转币地址
      // mentionCoinNumber: "", //提币数量
      show: false,
      canWithdrawal: false,
      usdtBalance: "", //可用余额
      id: "", //矿机编号
      routerParams: {}, //矿机信息
      amount: 1, //购买数量
      total: "", //总金额
      payState: false,
      loading: false,
      diaShow: false,
      diaShow2: false,
    }
  },
  components: {},
  computed: {},
  mounted() {
    this.id = this.$route.query.id
    this.getHomeList()
  },
  methods: {
    close(type) {
      if (type == true) {
        this.diaShow = true
      } else {
        this.diaShow = false
      }
    },
    close2(type) {
      if (type == true) {
        this.diaShow2 = true
      } else {
        this.diaShow2 = false
      }
    },
    goRecharge() {
      this.$router.push("/electricitytopup")
    },
    getHomeList() {
      this.$request
        .post("/kjInfo/getKjInfo", { id: this.id })
        .then(
          (res) => {
            this.routerParams = res.data
            this.routerParams.price = this.routerParams.price.slice(0, -4) * 1
            this.total = this.routerParams.price
            this.getUserInfo()
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
    getUserInfo() {
      this.$request
        .post("/user/userInfo")
        .then(
          (res) => {
            this.usdtBalance = res.data.usdtBalance
            // 执行成功的回调函数
            this.textChange(1)
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
    textChange(e) {
      this.amount = e
      // console.log(this.amount, "amount")
      // console.log(this.routerParams.price, "this.routerParams.price")
      this.total = e * this.routerParams.price
      // console.log(this.total, "total")
      // console.log(this.usdtBalance, "usdtBalance")
      if (this.total <= this.usdtBalance) {
        this.canWithdrawal = true
        this.close2(false)
      } else {
        this.canWithdrawal = false
        this.close2(true)
      }
    },
    payShow(type) {
      this.show = !this.show
      if (type == 2) {
        this.payState = false
        this.$router.go(-1)
      }
    },
    // 下单
    pay() {
      this.loading = true
      // this.payState = true
      const params = {
        kjId: this.id,
        amount: this.amount,
        total: this.total,
      }
      this.$request
        .post("/user/order", params)
        .then(
          (res) => {
            this.loading = false
            if (res.code == 200) {
              this.payState = true
              this.$toast(res.msg)
            } else {
              this.payState = false
              this.$toast(res.msg)
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.kjBuy {
  font-weight: bolder;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f9f9f9;
  .kj {
    width: 100%;
    height: 256px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px 15px 20px;
    margin-bottom: 12px;
    .item1 {
      padding-bottom: 15px;
      display: flex;
      .item1_left {
        width: 100px;
        border-radius: 8px;
        margin-right: 11px;
      }
      .item1_right {
        width: 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        .item1_right_title {
          text-align: left;
          font-size: 16px;
          color: #4a4a4a;
          margin-bottom: 13px;
        }
        .item1_right_content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item1_right_content_left {
            font-size: 20px;
            line-height: 20px;
            color: #ff3500;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    .item2 {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 15px;
      color: #4a4a4a;
    }
  }
  .type {
    width: 100%;
    height: 130px;
    background: #ffffff
      linear-gradient(
        180deg,
        rgba(255, 213, 40, 0.07) 0%,
        rgba(255, 213, 40, 0) 49%,
        rgba(242, 180, 1, 0) 100%
      );
    border-radius: 8px;
    padding: 13px 16px 0;
    .title {
      font-size: 16px;
      color: #333333;
      margin-bottom: 18px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: #4a4a4a;
      font-weight: 600;
      .left {
        width: 145px;
        height: 50px;
        background: #f8de4d;
        border-radius: 8px;
        line-height: 50px;
      }
      .right {
        width: 145px;
        height: 50px;
        background: #f0f0f0;
        border-radius: 8px;
        line-height: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 4px;
      }
    }
  }
}

.footer {
  padding: 0 20px;
  width: 100%;
  height: 70px;
  margin-top: 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  font-weight: bolder;
  color: #999999;
  .right {
    width: 127px;
    height: 50px;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    background: #e1e1e1;
    color: #ffffff;
    &.disabled {
      background: #f8de4d;
      color: #4a4a4a;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    .sxf {
      display: inline-block;
      // margin-left: 6px;
      // color: #333333;
    }
    span {
      color: #333333;
    }
  }
}

.checkstand {
  width: 100%;
  height: 450px;
  background: #ffffff;
  // border-radius: 8px 8px 0px 0px;
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  padding: 0 30px;
  position: relative;
  .money {
    margin-top: 24px;
    font-size: 36px;
    color: #333333;
    font-weight: 600;
    span {
      font-size: 20px;
      color: #333333;
    }
  }
  .list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 36px 0 26px;
    .left {
      font-size: 17px;
      color: #333333;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin-right: 10px;
      }
    }
    .right {
      font-size: 14px;
      color: #4a4a4a;
    }
  }
  .pay_button {
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    // width: 100%;
    height: 50px;
    background: #f8de4d;
    border-radius: 25px;
    font-size: 17px;
    color: #4a4a4a;
    font-weight: 600;
  }

  .ok {
    margin: 30px auto 6px;
  }
  .font {
    font-size: 20px;
    color: #333333;
    font-weight: 600;
  }
  .font2 {
    margin-top: 8px;
    font-size: 14px;
    color: #999999;
    font-weight: 600;
  }
}

::placeholder {
  font-size: 15px;
  color: #999999;
  font-weight: bolder;
}
</style>

<style lang="scss">
.van-field__control:disabled {
  color: #333333 !important;
}

.van-action-sheet__header {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  padding-top: 10px;
}
.van-action-sheet__close {
  left: 0 !important;
  right: auto;
}

.van-dialog__content {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.van-field__control {
  text-indent: 10px;
}
</style>
