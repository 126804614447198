<template>
  <div class="help">
    <div class="help_title">
      {{ $t("about.title") }}
    </div>
    <div class="quest_box">
      <div class="quest_content">
        {{ $t("about.content") }}
        <br />
        <br />
        {{ $t("about.content2") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "my",
  data() {
    return {
      coinNumber: "",
      coinType: "ETH",
      filCoinType: "FIL",
      coinCny: "",
      authStatus: null,
      isShowExitView: false,
      rmbBalance: "",
      filBalance: "",
      filRmb: "",
      uid: "",
    }
  },
  created() {
    this.getMeInforMation()
  },
  components: {},
  mounted() {},
  methods: {
    go(url) {
      this.$router.push(url)
    },
    // 获取我的个人信息
    getMeInforMation() {
      this.$request
        .post("/user/userInfo")
        .then(
          (res) => {
            if (res.code == 200) {
              // this.coinNumber = res.data.balance
              // this.coinCny = res.data.rmb
              // this.authStatus = res.data.authStatus
              // this.filBalance = res.data.filBalance
              // this.filRmb = res.data.filRmb
              // this.rmbBalance = res.data.rmbBalance
              this.uid = res.data.uid
            } else {
              // this.$toast(res.msg)
              if (res.code == 1018) {
                this.$router.push("/login")
              }
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {
          this.$toast("短信发送失败")
        })
    },
    //点击退出
    exit() {
      this.isShowExitView = true
    },
    //提现
    withdrawa(symbol) {
      this.$router.push({
        path: "/Withdrawal",
        query: {
          symbol: symbol,
        },
      })
    },
    //去交易记录
    gotoRecordList(symbol) {
      if (symbol != "") {
        this.$router.push({
          path: "/MentionRecord",
          query: {
            symbol: symbol,
          },
        })
      } else {
        this.$router.push({
          path: "/electricity",
          // query: {
          //   symbol: symbol
          // }
        })
      }
    },
    //确定退出
    sure() {
      localStorage.removeItem("token")
      this.$router.push("/login")
      this.isShowExitView = false
    },
    //取消退出
    cancel() {
      this.isShowExitView = false
    },
  },
}
</script>
<style scoped lang="scss">
.help {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  padding: 20px;
  text-align: left;
  .help_title {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    margin-bottom: 11px;
  }
  .quest_box {
    width: 100%;
    padding: 20px 16px;
    background: #ffffff;
    border-radius: 8px;
    .quest_title {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
    }
    .quest_content {
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-top: 16px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ededed;
    }
  }
}
</style>
