<template>
  <div class="kjDetails">
    <!-- <img class="body-text-img" src="../../assets/home/315@2x.png" alt /> -->
    <div class="top">
      <img class="kj-img" src="../assets/detail/kj.png" alt="" />
      <div class="kj-name">
        <span class="left" style="font-weight: bolder">
          {{ routerParams.name }}
        </span>
        <span class="right" style="font-weight: bolder">
          {{ routerParams.price }}
        </span>
      </div>
      <img src="../assets/detail/yjbh.png" alt="" class="kj-img2" />
      <!-- <div class="kj-nh" style="font-weight: bolder;">
        Power Consumption per Unit Hashrate:
        {{ routerParams.minbuyCalculation }}
      </div> -->
      <div class="kj-reason">
        <div>
          <img class="zan" src="../assets/detail/zan.png" alt="" />
          <!-- <img class="tjly" src="../assets/detail/tjly.png" alt="" /> -->

          {{ $t("product_title.highlights") }}
        </div>
        <div style="margin-top: 15px">
          {{ routerParams.locate }}
        </div>
      </div>
    </div>
    <!-- <img style="margin: 10px 0" src="../assets/detail/rxhd.png" alt="" /> -->
    <van-collapse
      v-model="activeNames"
      style="font-weight: bolder; color: #333333"
    >
      <van-collapse-item :title="$t('product.title')" name="1">
        <div class="body-bg">
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.one.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.one.right") }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.two.left") }}
            </span>
            <span class="body-text-right">{{
              routerParams.calculationPower
            }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.three.left") }}
            </span>
            <span class="body-text-right">{{ routerParams.powerCost }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.four.left") }}
            </span>
            <span class="body-text-right">{{ routerParams.locatePrice }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.five.left") }}
            </span>
            <span class="body-text-right">{{ routerParams.preElectric }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.six.left") }}
            </span>
            <span class="body-text-right">
              {{ routerParams.electricityCost }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.seven.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.seven.right") }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.eight.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.eight.right") }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.nine.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.nine.right") }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.ten.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.ten.right") }}
            </span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">
              {{ $t("product.eleven.left") }}
            </span>
            <span class="body-text-right">
              {{ $t("product.eleven.right") }}
            </span>
          </div>
          <!-- <div class="body-text-body">
            <span class="body-text-left">矿机型号</span>
            <span class="body-text-right"
              >{{ routerParams.typeName }}{{ routerParams.batchNum }}</span
            >
          </div>
          <div class="body-text-body">
            <span class="body-text-left">矿场位置</span>
            <span class="body-text-right">{{ routerParams.locate }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">每日电费</span>
            <span class="body-text-right">{{
              routerParams.electricityCost
            }}</span>
          </div>
          <div class="body-text-line"></div>
          <div class="body-text-body">
            <span class="body-text-left">矿机价格</span>
            <span class="body-text-right">{{ routerParams.price }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">质保费用</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">预估单日收益</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div>
          <div class="body-text-body">
            <span class="body-text-left">Platform Management Fee</span>
            <span class="body-text-right">{{ routerParams.qualityCost }}</span>
          </div> -->
        </div>
      </van-collapse-item>
      <van-collapse-item class="content_item" :title="$t('fee.title')" name="2">
        <div class="quest_content">
          {{ $t("fee.one") }}
        </div>
        <div class="quest_content">
          {{ $t("fee.two") }}
        </div>
        <div class="quest_content">
          {{ $t("fee.three") }}
        </div>
      </van-collapse-item>
      <van-collapse-item
        class="content_item"
        :title="$t('purchase.title')"
        name="3"
      >
        <div class="quest_content">
          {{ $t("purchase.one") }}
        </div>
        <div class="quest_content">
          {{ $t("purchase.two") }}
        </div>
        <div class="quest_content">
          {{ $t("purchase.three") }}
        </div>
      </van-collapse-item>
      <van-collapse-item
        class="content_item"
        :title="$t('output.title')"
        name="4"
      >
        <div class="quest_content">
          {{ $t("output.content") }}
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('help.title')" name="5">
        <div class="quest_title">
          <!-- How are hosting fees deducted? -->
          {{ $t("help.one.title") }}
        </div>
        <div class="quest_content">
          <!-- Hosting fees are automatically deducted from daily output. -->
          {{ $t("help.one.content") }}
        </div>
        <div class="quest_title">
          {{ $t("help.two.title") }}
        </div>
        <div class="quest_content">
          {{ $t("help.two.content") }}
        </div>
        <div class="quest_title">
          {{ $t("help.three.title") }}
        </div>
        <div class="quest_content">
          {{ $t("help.three.content") }}
          <br />
          <br />
          {{ $t("help.three.content2") }}
        </div>
        <div class="quest_title">
          {{ $t("help.four.title") }}
        </div>
        <div class="quest_content">
          {{ $t("help.four.content") }}
        </div>
        <div class="quest_title">
          {{ $t("help.five.title") }}
        </div>
        <div class="quest_content">
          {{ $t("help.five.content") }}
          <br />
          <br />
          {{ $t("help.five.content2") }}
        </div>
      </van-collapse-item>
    </van-collapse>
    <div class="kj_sm">
      <div class="footer-title">
        {{ $t("disclaimer.title") }}
      </div>
      <div class="footer-body">
        {{ $t("disclaimer.one") }}
        <br />
        <br />
        {{ $t("disclaimer.two") }}
        <br />
        <br />
        {{ $t("disclaimer.three") }}
      </div>
    </div>
    <!-- <div class="footer-body" v-html="routerParams.productExplain"></div> -->
  </div>

  <div class="kj_buy">
    <button class="button" @click="goBuy">
        {{ $t("kjList.payButton") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "kjDetails",
  data() {
    return {
      activeNames: ["1"],
      num: 0,
      routerParams: {},
      id: "",
    }
  },
  created() {},
  methods: {
    goBuy() {
      this.$router.push({
        path: "/kjBuy",
        name: "kjBuy",
        query: {
          id: this.id,
        },
      })
    },
    getHomeList() {
      this.$request
        .post("/kjInfo/getKjInfo", { id: this.id })
        .then(
          (res) => {
            if (res.code == 200) {
              this.routerParams = res.data
            }
            // 执行成功的回调函数
          },
          (error) => {
            console.log(error)
            // 执行失败的回调函数
          }
        )
        .catch(() => {})
    },
  },
  mounted() {
    this.id = this.$route.query.id
    this.getHomeList()
  },
  computed: {},
  components: {},
}
</script>
<style scoped lang="scss">
.kjDetails {
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  overflow: auto;
  padding: 14px 20px 20px;
  text-align: left;
  // border-radius: 12px;
  padding: 20px 15px 30px;
  .top {
    // height: 480px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 20px;
    .kj-img {
      height: 160px;
      margin-top: 20px;
    }
    .kj-name {
      width: 100%;
      margin-top: 46px;
      display: flex;
      justify-content: space-between;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      line-height: 25px;
      .left {
        font-size: 18px;
        color: #4a4a4a;
      }
      .right {
        font-size: 18px;
        color: #f8b34d;
      }
    }
    .kj-img2 {
      margin-top: 8px;
      width: 100%;
    }
    .kj-nh {
      width: 100%;
      margin-top: 16px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      text-align: left;
    }
    .kj-reason {
      margin-top: 20px;
      width: 100%;
      height: 124px;
      background: rgba(248, 222, 77, 0.12);
      border-radius: 8px;
      padding: 16px 7px 0 12px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 14px;
      color: #4a4a4a;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: left;
      .zan {
        width: 20px;
        margin-right: 8px;
      }
      .tjly {
        width: 68px;
      }
    }
  }
  .body-bg {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    padding: 20px 16px;
    background: #fff;
    border-radius: 12px;
    .body-text-img {
      margin-bottom: 20px;
    }
    .body-text-line {
      height: 1px;
      background: #f3f3f3;
      margin-bottom: 20px;
    }
    .body-text-body {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      line-height: 18px;
      .body-text-left {
        color: #93a5b2;
      }
      .body-text-right {
        text-align: right;
        color: #4a4a4a;
      }
    }
    .body-text-body:last-child {
      margin-bottom: 0px;
    }
  }
  .content_item {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
  .quest_title {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
  }
  .quest_content {
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 14px;
    color: #666666;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ededed;
  }
  .kj_sm {
    width: 100%;
    margin-top: 10px;
    padding: 16px 16px;
    background: #ffffff;
    // height: 250px;
    border-radius: 8px;
    color: #333333;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;

    .footer-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 8px;
      font-size: 14px;
      color: #ff3500;
    }
    .footer-body {
      width: 100%;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 1px;
      font-size: 13px;
      color: #999999;
    }
  }
}

.kj_buy {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  margin-top: 10px;
  width: 100%;
  height: 84px;
  background: #ffffff;
  padding: 17px 20px;
  .button {
    width: 100%;
    height: 50px;
    background: #f8de4d;
    border: 0;
    border-radius: 25px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: 600;
    font-size: 17px;
    color: #4a4a4a;
  }
}
.m-b-5 {
  margin-bottom: 5px;
}
</style>
<style lang="scss">
.van-cell__title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
}
.van-tabs__nav {
  background: #f8f8f8;
}
.van-tabs__line {
  background-color: #1677ff;
}
.van-collapse-item__content {
  font-weight: normal;
  color: #333333;
}
</style>
