import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/style/react.css';
import './assets/style/border.css';
import 'lib-flexible';
import './assets/style/font.css';
import request from '@/utils/request';
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/style/resetui.css';
import VueWechatTitle from 'vue-wechat-title';
import i18n from './language'



createApp (App)
  .use (router)
  .use (store)
  .use (VueWechatTitle)
  .use (Vant)
  .use(request)
  .use(i18n)
  .mount ('#app');

router.afterEach ((to, from, next) => {
  window.scrollTo (0, 0);
});

router.beforeEach((to, from, next) => {

  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
  if (to.matched.some (record => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    // console.log (localStorage.token);
    // console.log ('需要登录');
    if (localStorage.token) {
      // 判断当前的token是否存在 ； 登录存入的token
      next ();
    } else {
      // next();
      next ({
        path: '/login',
        // query: {redirect: to.fullPath}, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next ();
  }
});
