<template>
  <div class="footer">
    <van-tabbar
      v-model="active"
      class="active_tab"
      active-color="#333"
      inactive-color="#999"
    >
      <van-tabbar-item
        v-for="(item, index) in tabArr"
        :key="index"
        replace
        :to="item.url"
        @change="onChange"
      >
        <span>{{ item.name }}</span>
        <template #icon="props">
          <img
            style="width:26px;height:26px;"
            :src="props.active ? item.icon.active : item.icon.inactive"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import i18n from '../language/index.js';

export default {
  name: "footer",
  watch: {
    $route() {
      this.setTabActive()
    },
  },
  data() {
    return {
      active: 0,
      tabArr: [
        {
          name: i18n.global.t('tabbar.Home'),
          url: "/home",
          icon: {
            active:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/home_active.png",
            inactive:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/home.png",
          },
        },
        {
          name: i18n.global.t('tabbar.Mining'),
          url: "/kjList",
          icon: {
            active:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/kj_active.png",
            inactive:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/kj.png",
          },
        },
        // {
        //   name: "算力套餐",
        //   url: "/kjList",
        //   icon: {
        //     active:
        //       "https://alipic.lanhuapp.com/SketchPngb7224f8bc837faf56eccd75de4472ea1eb23216a359540075952d883235837ea",
        //     inactive:
        //       "https://alipic.lanhuapp.com/SketchPng7e6842f19d4855ecd78ca0ef661a46bebcf1570cf3415548e90e2619ccd1474c"
        //   }
        // },
        {
          name: i18n.global.t('tabbar.kjOrder'),
          url: "/kjOrder",
          icon: {
            active:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/order_active.png",
            inactive:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/order.png",
          },
        },
        {
          name: i18n.global.t('tabbar.Me'),
          url: "/my",
          icon: {
            active:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/my_active.png",
            inactive:
              "https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/bafybeie2b52gxsrms2mdednctfaraw2hd74iepiferlecmzpxjcrg3pzrm/my.png",
          },
        },
      ],
    }
  },
  created() {
    this.setTabActive()
  },
  methods: {
    setTabActive() {
      this.active = this.tabArr.findIndex(
        (item) => item.url === this.$route.path
      )
    },
    onChange(val) {
      // console.log(index, props);
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 70px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px -4px 15px 0px rgba(158, 158, 158, 0.15);
  // border-top: 1px solid #f1f1f1;
  color: #999999;
  font-size: 12px;
  .van-tabbar {
    height: 70px !important;
  }
}
</style>
